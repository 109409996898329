import { sellerOrderStatusMapper } from 'store/api/entities/order/mappers/sellerOrderStatusMapper';
import { PaginatedData } from 'store/api/entities/types';
import { SearchOrdersResponse } from 'store/api/entities/order/requesters/searchSellerOrders.contract';
import { SellerOrderForSearchTable } from 'store/api/entities/order/models';
import { priceMapper } from 'store/api/entities/shared/mappers';
import { productImageMapper } from '../../customerOrders/mappers/productImageMapper';

export const searchSellerOrdersMapper = (
  response: SearchOrdersResponse
): PaginatedData<SellerOrderForSearchTable> => {
  return {
    content: response.content.map((order) => ({
      id: order.id,
      referenceNumber: order.reference_number,
      status: sellerOrderStatusMapper(order.status),
      transactionId: order.transaction_id,
      cortId: order.cort_id || undefined,
      cortHrr: order.cort_hrr || undefined,
      date: order.created_at,
      addresses: {
        relayName: order.shipping_address.relay_name,
        addressLine1: order.shipping_address.address_line1,
        addressLine2: order.shipping_address.address_line2 || undefined,
        addressLine3: order.shipping_address.address_line3 || undefined,
        city: order.shipping_address.city,
        country: order.shipping_address.country,
        zipcode: order.shipping_address.zipcode,
      },
      totalProducts: order.total_products,
      products: order.products.map((product) => ({
        id: product.id,
        image: productImageMapper(product.image_url),
        title: product.title,
      })),
      totalAmount: priceMapper(
        order.total_amount.amount,
        order.total_amount.currency
      ),
      customer: order.customer,
    })),
    pagination: response.pagination,
  };
};
